import React from 'react';
import Post from '../../components/Post';

const RelatedPost = ({related}) => {
  const {edges: posts} = related;
  return (
    <div className="related-posts">
      <h2 className="related-post-title">関連記事</h2>
      <div className="row related-post-content">
        {posts.map(({node}) => (
          <Post key={node.id} post={node} />
        ))}
      </div>
    </div>
  );
};

export default RelatedPost;
